import React, { useState } from 'react';
import Layout from "../components/Layout/Layout";
import LampComponent from "../components/Home/Lamp/LampComponent";
import Header from "../components/Home/Header/HeaderComponent";
import Description from "../components/Home/Description/DescriptionComponent";
import FooterComponentCentered from "../components/Home/Footer/FooterComponentCentered";
import SEO from "../components/Seo/seo";
import styled from 'styled-components';
import { theme } from '../utils/theme';


// ---- Home Styles----

const StyledHomeWrapper = styled.div`
min-height: 100vh;
background: ${props => props.children[1].props.children[1].props.children[1].props.children.props.children === 'OFF' ? 'linear-gradient(to right top, #0f000d, #0e010c, #0e010b, #0d020a, #0c0309, #1b0e15, #27151d, #351b24, #57272f, #793633, #974a30, #ae6428)' : 'linear-gradient(to right top, #130111, #140112, #160113, #170114, #180115, #190216, #1a0316, #1b0417, #1d0618, #1f0919, #200b1a, #220d1b)'};

${theme.media.desktop} {
min-height: 100%;
background: ${props => props.children[1].props.children[1].props.children[1].props.children.props.children === 'OFF' ? 'linear-gradient(to top, #0f000d, #0e010c, #0e010b, #0d020a, #0c0309, #1c0f17, #2a171f, #391d27, #5e2c34, #833e3a, #a5553a, #bf7234)' : 'linear-gradient(to right top, #130111, #140112, #160113, #170114, #180115, #190216, #1a0316, #1b0417, #1d0618, #1f0919, #200b1a, #220d1b)'};
}

${theme.media.mobile} {
${theme.flex.centeredColumn};
padding: 0;
margin: 0;
overflow-x: hidden;
}
`;
const StyledContentWrapper = styled.div`
${theme.flex.centered};
justify-content: flex-end;
padding-right: 10%;

${theme.media.cloud} {
height: 100%;
}

${theme.media.desktop} {
${theme.flex.centeredColumn};
flex-direction: column-reverse;
padding: 0;
margin: 0;
}
`;
const StyledSwitch = styled.div`
position: absolute;
top: 40%;
right: 5%;
width: 100px;
height: 100px;
border: 2px solid;
text-align: center;
border-color: ${props => props.children.props.children === 'OFF' ? '#0f000b' : '#c8c7e2'};
border-radius: 50%;
cursor: pointer;
z-index: 20;

h2 {
margin: 0;
line-height: 100px;
color: ${props => props.children.props.children === 'OFF' ? '#0f000b' : '#c8c7e2'};
z-index: 20;
}

@media not all and (hover: none) {
    &:hover {
        border: 2px solid #BF7234;
        color: #BF7234;
    }
    &:hover h2 {
        color: #BF7234;
    }
}

${theme.media.desktop} {
position: static;
border-color: #BF7234;
margin: 100px auto;

h2 {
    color: #BF7234;
    }
}
`;
const StyledSwitchAndLampWrapper = styled.div`
`


const IndexPage = () => {

    const [active, setActive] = useState('false');

    return (
        <Layout>
            <SEO title="Home" />

            <StyledHomeWrapper >
                <Header />
                <StyledContentWrapper >
                    <Description />

                    <StyledSwitchAndLampWrapper >
                        <LampComponent active={active} />
                        <StyledSwitch onClick={() => active === 'true' ? setActive('false') : setActive('true')}>
                            {active === 'true' ? <h2>OFF</h2> : <h2>ON</h2>}
                        </StyledSwitch>
                    </StyledSwitchAndLampWrapper>

                </StyledContentWrapper>

                <FooterComponentCentered />

            </StyledHomeWrapper>
        </Layout>
    )
}

export default IndexPage;