import React from "react";
import styled from 'styled-components';
import { theme } from '../../../utils/theme';
import { Link } from "gatsby";


// ---- Styles ----

const StyledDescription = styled.div`
max-height: 70vh;
position: absolute;
top: 22vh;
left: 0;
width: 50%;
margin-left: 10%;
padding: 5%;
z-index: 1;

h1 {
    font-size: 3rem;
}

p {
    font-size: 1.1rem;
    line-height: 30px;
}

a {
    ${theme.buttonMain};
    font-weight: 100;
    font-size: 1.4rem;
}

${theme.media.cloud} {
margin-left: 5%;
width: 60%;

h1 {
    font-size: 2rem;
}

p {
    font-size: .8rem;
    line-height: 22px;
}
}

${theme.media.desktop} {
position: static;
margin-left: 0;
margin-right: 0;
width: 100%;
max-height: 100%;
} 

${theme.media.mobile} {
${theme.flex.centeredColumn};
padding: 5%;
text-align: center;

h1 {
    font-size: 2.5rem;
}
}
`;

const DescriptionComponent = () => {

    return (

        <StyledDescription >
            <h1 itemScope>Handcrafted Furniture</h1>

            <p itemProp="description">Only a single piece is produced in the manufacturing process, employing meticulous handicraft techniques.</p>

            <p itemProp="materials">The materials used are predominantly of natural origin, and the entire project took over a year to complete.</p>

            <p itemProp="name">The majority of the lamp's elements, including components such as the feet, dimmer knob, core with the light fitting, and the supporting structure, are crafted by hand.</p >

            <Link title="This is a link to contact subpage" as="a" to="/contact" aria-label="contact">contact</Link>
            <Link title="This is a link to details subpage" as="a" to="/details" aria-label="details">details</Link>
            <Link title="This is a link to gallery subpage" as="a" to="/gallery" aria-label="gallery">gallery</Link>

        </StyledDescription >

    )
}

export default DescriptionComponent;