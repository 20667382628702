import React from "react"
import styled from 'styled-components'
import { theme } from '../../../utils/theme';


// ---- Images ----

import logo from "../../../images/logo-web.webp"


// ---- Styles ----

const StyledHeader = styled.div`
${theme.flex.centered};
justify-content: flex-start;
width:50vw;
position: absolute;
top: 0;
left: 0;

${theme.media.cloud} {
${theme.flex.centeredColumn};
}

${theme.media.desktop} {
${theme.flex.centeredColumn};
width: 100%;
position: static;
}
`;
const LogoWrapper = styled.div`
margin: 50px;

img {
    max-height: 150px;
    }

${theme.media.mobile} {
${theme.flex.centeredColumn};
margin: 50px 0; 

img {
    padding: 2%;
    max-height: 135px;
    }
}
`;


const HeaderComponent = () => (

    <StyledHeader>
        <LogoWrapper>
            <img src={logo} alt="logo" />
        </LogoWrapper>
    </StyledHeader>

)

export default HeaderComponent;